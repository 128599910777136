@media only screen and (max-width: 600px) {
  body,
  #root {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.result-label {
  cursor: pointer;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ui.menu {
  width: 100%;
  max-width: 768px;
  margin: auto !important;
  margin-top: 1rem !important;
}

.ui.segment {
  max-width: 512px;
  margin: auto;
  background-color: #fcfcfc;
  color: #000000 !important;
  overflow: hidden;
}

.ui.segment.large {
  max-width: 768px;
}
.ui.segment.huge {
  max-width: 1024px;
}

.ui.segment p {
  color: #000000 !important;
}

body {
  background-color: #000000;
  color: #eeeeee;
}

.question {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Skip btn */
.angle.double.down.icon {
  width: 100%;
}

/* Change BG for only the slider handles */
.price-slider .slider-handles div:not(.price-slider .slider-handles div div),
.price-slider .slider-tracks div {
  background-color: #00b5ad !important;
}

.price-slider .slider-handles div div:before {
  content: "$";
  margin-left: -10px !important;
}

.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2;
}

.blur,
.blur * {
  transition: opacity 200ms ease-in-out !important;
}

.hidden {
  opacity: 0;
  height: 0;
}

.v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.errCls {
  color: #656565;
  margin-left: -10px !important;
  font-size: 1.28571429em !important;
  line-height: 1.28571429em !important;
  cursor: pointer;
}

.errCls:hover {
  color: #000000;
}

.ui.attached.tabular.menu {
  overflow-y: hidden;
  white-space: nowrap !important;
}

.ui.attached.tabular.menu .item:not(.active) {
  color: #eeeeee !important;
}

tr:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

.ui.bottom.attached.segment.tab {
  margin: 0 !important;
}

.amazon {
  background-color: #eec34d !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.custom-loader {
  border: none !important;
}
